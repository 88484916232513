import React, {useEffect, useState} from 'react';
import logo from "../../assets/images/logo.png";
import {useParams} from 'react-router-dom';
import ios from "../../assets/images/ios-badge.png";
import android from "../../assets/images/google-play-badge.png";

const Product = () => {
    let { id } = useParams();
    const [productData, setProductData] = useState({});

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.location.href = 'clothesfriends:///product/'+ id.split(' ')[0];
        }
    }, [id]);

    useEffect(() => {
        fetch("https://api.clothes-friends.com/products/basic/" + id.split(' ')[0])
            .then((response) => response.json())
            .then((data) => setProductData(data))
            .catch(e => console.log(e))
    }, []);

    /*
    <a href={"intent:#Intent;scheme=clothesfriends:///product/"+ id + ";package=com.clothesfriends.app;end"}>
                    <p>
                        click me
                    </p>
                </a>*/
    return(
        <div className="App">
            <header className="App-header">

                <h1>CLOTHESfriends</h1>
                {productData.images && <div className={"product-card"}>
                    <img src={productData.images[0]} className={"product-image"}/>
                    <h3 className={"product-title"}>{productData.title}</h3>
                    <p className={"product-brand"}>{productData.brand}</p>
                </div>}
                <h4 className={"product-info"}>Lade dir jetzt die App herunter um das Produkt anzusehen. Wenn du die App bereits hast, öffnet sie sich automatisch.</h4>
                <a href={"intent:#Intent;scheme=clothesfriends:///product/"+ id + ";package=com.clothesfriends.app;end"}>
                    <p>
                        App öffnen
                    </p>
                </a>
                <a href={"https://apps.apple.com/de/app/clothesfriends-share-fashion/id1560847419?l=en"}>
                    <img src={ios} className={"downloadLogo"}/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=com.clothesfriends.app"}>
                    <img src={android} className={"downloadLogo"}/>
                </a>
            </header>
        </div>
    );
};


export default Product;
