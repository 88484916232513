import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Product from "./components/pages/Product";
import User from "./components/pages/User";
import Home from "./components/pages/Home";
import OpenApp from "./components/pages/OpenApp";

function App() {

  return (
      <Router>
        <div>
          <Switch>
            <Route path="/u/:username">
              <User />
            </Route>
            <Route path="/p/:id">
              <Product />
            </Route>
            <Route path="/home">
              <OpenApp />
            </Route>
            <Route path="/">
              <Home />
            </Route>

            <Route>
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
