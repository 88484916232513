import React from 'react';
import logo from "../../assets/images/logo.png";
import ios from "../../assets/images/ios-badge.png";
import android from "../../assets/images/google-play-badge.png"

const Home = () => {
    return(
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>CLOTHESfriends</h1>
                <h4>Lade dir jetzt die App herunter!</h4>
                <a href={"https://apps.apple.com/de/app/clothesfriends-share-fashion/id1560847419?l=en"}>
                    <img src={ios} className={"downloadLogo"}/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=com.clothesfriends.app"}>
                    <img src={android} className={"downloadLogo"}/>
                </a>
            </header>
        </div>
    );
};

export default Home;
