import React from 'react';
import logo from "../../assets/images/logo.png";
import {useParams} from 'react-router-dom';
import {useEffect} from "react";
import ios from "../../assets/images/ios-badge.png";
import android from "../../assets/images/google-play-badge.png";

const User = () => {
    let { username } = useParams();
    useEffect(() => {
        const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if (typeof window !== 'undefined') {
            window.location.href = isIos?'clothesfriends:///profile/'+ username.split(' ')[0]
                :"intent:#Intent;scheme=clothesfriends:///profile/"+username+";package=com.clothesfriends.app;end";
        }
    }, [username]);

    return(
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h1>CLOTHESfriends</h1>
                <h4>Lade dir jetzt die App herunter um den User anzusehen. Wenn du die App bereits hast, öffnet sie sich automatisch.</h4>
                <a href={"intent:#Intent;scheme=clothesfriends:///profile/"+ username + ";package=com.clothesfriends.app;end"}>
                    <p>
                        App öffnen
                    </p>
                </a>
                <a href={"https://apps.apple.com/de/app/clothesfriends-share-fashion/id1560847419?l=en"}>
                    <img src={ios} className={"downloadLogo"}/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=com.clothesfriends.app"}>
                    <img src={android} className={"downloadLogo"}/>
                </a>
            </header>
        </div>
    );
};


export default User;
